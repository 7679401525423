import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/menu.css';
import './assets/css/respornsive.css';
import './assets/css/radioandcheckbox.css';

import Home from './pages/home';
import Profile from './pages/profile';

function App() {
  return (
    <div className="App">
      <BrowserRouter basename=''>

        {/* <ScrollTotop /> */}

        <Routes>
          {/* <Navigation> */}
          {/* <Route path="*" element={<NotFound />} /> */}
          <Route exact path="/" element={<Home />} />
          <Route path="/profile/:id" element={<Profile />} />
          {/* <Route path="/login" element={<Login />} /> */}
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
