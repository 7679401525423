import React, { useEffect, useState, useRef } from 'react';

import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

import liff from '@line/liff';

import logo1 from '../assets/img//logo.svg';
import logo from '../assets/img//logo-white.svg';
import bg from '../assets/img/bg.jpg';
import profile from '../assets/img//profile.svg';
import Menu from '../components/menu'

import Loading from "../components/loading";

import { fetchRegister, getUser  } from '../services/authenService';

const Home = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [validated, setValidated] = useState(false);
    const [isTitle, setIsTitle] = useState(false)

    const [pictureUrl, setPictureUrl] = useState("");
    const [idToken, setIdToken] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [userId, setUserId] = useState("");

    const logout = () => {
        liff.logout();
        window.location.reload();
    }

    const initLine = () => {
        liff.init({ liffId: '2000149450-KbrLvVPP' }, () => {
            if (liff.isLoggedIn()) {
                runApp();
                // setIsTitle(false);
            } else {
                liff.login();
            }
        }, err => console.error(err));
    }

    const runApp = () => {
        const idToken = liff.getIDToken();
        setIdToken(idToken);
        liff.getProfile().then(profile => {
            console.log(profile);
            setDisplayName(profile.displayName);
            setPictureUrl(profile.pictureUrl);
            setStatusMessage(profile.statusMessage);
            setUserId(profile.userId);

            fetchUser(profile.userId)
            
            // console.log()

        }).catch(err => console.error(err));
    }

    const fetchUser = async (lineID) => {

        setIsLoading(true)
        // if (!auth || !auth.id)
        //     setTimeout(() => { window.location.reload() }, 500);

        const user = await getUser(lineID)

        // console.log(user)

        if (user.status === 200 || user.status === 'success') {

            // setUserData(user.data)
            // listReserve(startDate)
            window.location.href = '/profile/'+lineID    
             
        }

        setIsLoading(false)
    }

    const called = useRef(true)

    useEffect(() => {

        if (called.current) {
            // if (localStorage.getItem('accessToken'))
            // window.location.href = '/profile'

            // setTimeout(() => {
                // setIsTitle(false);
                initLine();                       
            // }, 1000);
        }

        return () => {
            called.current = false;
        }
    }, [])

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        // const { name, email } = formInfo;


        if (form.checkValidity() === false) {
            event.stopPropagation();

        } else {

            setIsLoading(true)

            await fetchRegister(dataForm).then(
                response => {
                    if (response.status === 'success') {
                        setTimeout(() => {
                            window.location.href = "/profile/"+userId;
                        }, 100);

                        // Swal.fire({
                        //     position: 'center-center',
                        //     icon: 'success',
                        //     title: '',
                        //     html: '<h5>บันทึกข้อมูลเรียบร้อยแล้ว</h5>',
                        //     showConfirmButton: false,
                        //     timer: 1500
                        // }).then((result) => {
                        //     window.location.href = "/login";
                        // })
                    }
                }).catch(e => {
                    // setResult(e.response.status === 409 ? '! ไม่สามารถใช้อีเมลนี้ได้เนื่องจากมีในระบบแล้ว' : null)
                    // window.scrollTo({ top: 0,behavior: "smooth"});
                    console.log(e.response.data.messages.errors)

                    setIsLoading(false)
                })
        }
        setValidated(true);
    };
    return (
        <>
            {
                isTitle ?
                    <div className="container-fluid" style={{ zIndex: "1", minHeight: '100vh' }}>
                        <div className="row justify-content-center" >
                            <div className='col-12 col-md-3 col-lg-3 position-relative p-0 bg-norepeat' style={{ backgroundImage: `url(${bg})`, height: '100vh' }}>
                                <div className='position-absolute-center'>
                                    <img src={logo} />
                                </div>
                            </div>
                        </div>
                    </div >
                    :
                    <div className="container-fluid col-12 col-md-3 col-lg-3 overflow-hidden">
                        <div className="row justify-content-center">

                            <div className='col-12 col-md-12 col-lg-12 position-relative p-0 bg-norepeat' style={{ backgroundImage: `url(${bg})`, minHeight: '100vh' }}>
                                {/* <Menu udata={null} /> */}
                                {isLoading ? <Loading /> : null}
                                <div className="">
                                    {/* <Link to="/">Home</Link> */}
                                    {/* <img src={profile} className="w-50 px-5 mt-5 box-img-shadow" style={{}} /> */}
                                </div>
                                <div className='pt-5 pb-4' style={{ background: "#fff", borderTopLeftRadius: "40px", borderTopRightRadius: "40px", marginTop: "80px", minHeight: '100vh' }}>
                                    <div className='px-3'>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} >
                                            <div className='row text-start'>
                                                <div className='col-12 text-center'>
                                                    <img src={logo1} />

                                                    {/* <div style={{ textAlign: "center" }}>
                                                        <img src={pictureUrl} width="300px" height="300px" />
                                                        <p style={{ textAlign: "left", marginLeft: "20%", marginRight: "20%", wordBreak: "break-all" }}><b>id token: </b> {idToken}</p>
                                                        <p style={{ textAlign: "left", marginLeft: "20%", marginRight: "20%", wordBreak: "break-all" }}><b>display name: </b> {displayName}</p>
                                                        <p style={{ textAlign: "left", marginLeft: "20%", marginRight: "20%", wordBreak: "break-all" }}><b>status message: </b> {statusMessage}</p>
                                                        <p style={{ textAlign: "left", marginLeft: "20%", marginRight: "20%", wordBreak: "break-all" }}><b>user id: </b> {userId}</p>

                                                        <button onClick={() => logout()} style={{ width: "100%", height: 30 }}>Logout</button>
                                                    </div> */}
                                                </div>
                                                <div className='col-12'>
                                                    <div className='mt-5 font-size-24 font-weight-700'>Privilege</div>
                                                    <div className="mt-4 position-relative">
                                                        <label htmlFor="firstName" className="form-label label-custom font-size-12 font-weight-600">First name</label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="First name"
                                                            className="input-custom font-size-14"
                                                            name="firstName"
                                                        />
                                                        <div className="text-danger text-start mt-1"></div>
                                                    </div>
                                                    <div className="mt-4 position-relative">
                                                        <label htmlFor="lastName" className="form-label label-custom font-size-12 font-weight-600">Last name</label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Last name"
                                                            className="input-custom font-size-14"
                                                            name="lastName"
                                                        />
                                                        <div className="text-danger text-start mt-1"></div>
                                                    </div>
                                                    <div className="mt-4 position-relative">
                                                        <label htmlFor="mobile" className="form-label label-custom font-size-12 font-weight-600">Mobile Number</label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Mobile Number"
                                                            className="input-custom font-size-14"
                                                            name="mobile"
                                                        />
                                                        <div className="text-danger text-start mt-1"></div>
                                                    </div>
                                                    <div className="mt-4 position-relative">
                                                        <label htmlFor="email" className="form-label label-custom font-size-12 font-weight-600">Email</label>
                                                        <Form.Control
                                                            required
                                                            type="email"
                                                            placeholder="Email"
                                                            className="input-custom font-size-14"
                                                            name="email"
                                                        />
                                                        <div className="text-danger text-start mt-1"></div>
                                                    </div>
                                                    <div className="mt-4 position-relative">
                                                        <label htmlFor="email" className="form-label label-custom font-size-12 font-weight-600">Workplace</label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Workplace"
                                                            className="input-custom font-size-14"
                                                            name="workplace"
                                                        />
                                                        <div className="text-danger text-start mt-1"></div>
                                                    </div>
                                                    <div className="mt-4 position-relative">
                                                        <label htmlFor="detailDrink" className="form-label font-weight-600">You Practice information (please select from below)</label>
                                                        <div className="ex-radio mb-2">
                                                            <input type="radio" name="product" value="Orthodontist" />
                                                            <span className="radio_one color-gray font-size-14">Orthodontist</span>
                                                        </div>
                                                        <div className="ex-radio mb-2">
                                                            <input type="radio" name="product" value="GP trained orthodontics" />
                                                            <span className="radio_one color-gray font-size-14">GP trained orthodontics</span>
                                                        </div>
                                                        <div className="ex-radio mb-2">
                                                            <input type="radio" name="product" value="GP" />
                                                            <span className="radio_one color-gray font-size-14">GP</span>
                                                        </div>
                                                        <div className="ex-radio mb-2">
                                                            <input type="radio" name="product" value="Others specialty" />
                                                            <span className="radio_one color-gray font-size-14">Others specialty</span>
                                                        </div>
                                                    </div>
                                                    <input type="hidden" name="img" value={pictureUrl} />
                                                    <input type="hidden" name="lineName" value={displayName} />
                                                    <input type="hidden" name="lineID" value={userId} />
                                                    <input type="hidden" name="lineToken" value={idToken} />

                                                    <div className="mt-5 text-end">
                                                        <button onClick={() => window.location.reload()} className="btn btn-default font-size-14 pe-3 font-weight-600">Cancel</button>
                                                        <button className="btn btn-register px-5 py-2 font-size-14 font-weight-600">REGISTER</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div className='font-size-10 color-white py-2' style={{ backgroundColor: "#532C76" }}>Copyright © 2023 Modern Dental Group Limited. All rights reserved.</div>
                            </div>
                        </div>
                    </div >
            }
        </>
    );
};

export default Home;