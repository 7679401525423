import axios from 'axios'
const accessToken = localStorage.getItem('accessToken');
const base_api = process.env.REACT_APP_API_LINK;
const base_api_path = process.env.REACT_APP_API_BASE

const axiosHeader = axios.create({
    baseURL: '/',
    timeout: 0,
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': `${ accessToken ? 'Bearer ' + accessToken : '' }`
    }
})

const axiosJson = axios.create({
    baseURL: '/',
    timeout: 0,
    headers: {
      "Content-Type": "application/json",
      'Authorization': `${ accessToken ? 'Bearer ' + accessToken : '' }`
    }
})
const axiosPutHeader = axios.create({
    baseURL: '/',
    timeout: 0,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      'Authorization': `${ accessToken ? 'Bearer ' + accessToken : '' }`
    }
})

const fetchHeader = (method,params) => {
    const accessToken = localStorage.getItem('accessToken');
    let header = { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ accessToken}` ,
    }
    
    if( accessToken )
        header = { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ accessToken }` 
           // 'x-access-token' : accessToken ? accessToken : ''
        }

    return {
        method: method,
        headers: header,
        body: params ? params : ''
    }

}

const axiosGet = async(url) => {
    const response = await axiosHeader.get(url)
    if( !response ) return {status:'error'}
    return {status : 'success', data : response.data}
}

const axiosPost = async(url,params) => {
    const response = await axiosHeader.post(url,params)
    if( !response ) return {status:'error'}
    return {status : 'success', data : response.data}
}

const axiosFetchGet = async (url,params) => {
    const response = await axiosHeader.get(`${url}${params ? params : ''}`)
    if( response.status !== 200 ) return { status : 'error', message : response.message() }
    return { status : 'success', data : response.data }
}

const axiosFetchPost = async (url,params) => {
    const response = await axiosHeader.post(url,params)
    if( response.status !== 200 ) 
        return { status : 'error', message : response.message() }

    return { status : 'success', data : response.data }
}
const axiosFetchStore = async (url,params) => {
    const response = await axiosHeader.post(url,params)
    return response
}
const axiosJsonStore = async (url,params) => {
    return await axiosJson.post(url,params)
}
const axiosFetchPut = async (url,params) => {
    const response = await axiosHeader.post(url,params)
    return response
}

const axiosFetchDelete = async (url,params) => {
    const response = await axiosHeader.post(url,{_method : 'DELETE'})
    return response
}

export { 
    base_api, 
    base_api_path, 
    axiosHeader, 
    axiosJson,
    fetchHeader, 
    axiosPost, 
    axiosGet, 
    axiosFetchGet, 
    axiosFetchPost, 
    axiosFetchStore, 
    axiosFetchPut, 
    axiosFetchDelete,
    axiosJsonStore
}