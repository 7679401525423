import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';
import { Form, Dropdown, DropdownButton } from "react-bootstrap";

// import menuFile from '../assets/img/iconNew/calendar.svg';
// import menuMember from '../assets/img/iconNew/news.svg';
import logo from '../assets/img/logo-white.svg';
import close from '../assets/img/icon/close.svg';


// import userIcon from '../assets/img/iconNew/user.svg';
// import sorn from '../assets/img/iconNew/sorn.svg';
// import logout from '../assets/img/iconNew/logout.svg';

// import { fetchLogout } from '../services/authenService';

const Menuleft = ({ page, udata, ...data }) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [menusubOpen, setMenusubOpen] = useState({ view: false, id: 0 });
    const [modalLogin, setModalLogin] = useState({ view: false });

    const handleMenuChange = (state) => {
        setMenuOpen(state.isOpen)
    }

    const toggleMenu = () => {
        // setMenuOpen(true); 
        setMenuOpen(false)
    }
    const handleMenusubChange = (state, id) => {
        if (menusubOpen.id === id) {
            setMenusubOpen({ view: !menusubOpen.view, id: id })
        } else {
            setMenusubOpen({ view: true, id: id })
        }

    }

    const handleLogout = () => {
        // fetchLogout()
    }


    // console.log(udata)

    return (
        <>
            <div className="menu-res">
                {/* <div className="line-gra position-absolute"></div> */}
                <div className='row align-items-center'>
                    <div className='logo-home text-start py-2 col-4'>
                        {/* <Link to="/" className='btn-link-default border-0'>
                            <img src={logo} alt="" className="py-2 logo-res" style={{ height: "60px" }} />
                        </Link> */}
                       
                    </div>

                    <div className='right col-8'>
                        {/* <div className='d-md-flex justify-content-end pb-3'>
                            <Link to="/login" className='font-weight-600 py-3 px-4 btn-login'>เข้าสู่ระบบ <img src={userIcon} className='ms-3' /></Link>
                        </div> */}

                        <Menu className='bg-menu-m box-bg-img-cover p-3' right pageWrapId={"page-wrap"} uterContainerId={"outer-container"} isOpen={menuOpen} onStateChange={(state) => handleMenuChange(state)}>
                            <div onClick={() => toggleMenu()} className='py-2 text-end px-2 font-20 cursor-pointer'>
                                <img src={close} className="" style={{ width: "30px" }} />
                            </div>
   
                            {udata ?
                                <ul className='menu-m'>
                                    <li className={menusubOpen.id === 2 ? menusubOpen.view ? "nav-item has-submenu menu-open" : "nav-item has-submenu" : "nav-item has-submenu"}>
                                        <a className="nav-link" href="#" onClick={(e) => handleMenusubChange(e, 2)}>
                                            {/* <img src={sorn} className="arrow90 ms-2" style={{ width: "9px" }} />  */}
                                            การจัดการปฏิทินการประชุม
                                        </a>
                                        <ul className="submenu collapse">
                                            <li>
                                                <a href="/schedule/0" className="btn-link-default">
                                                    ห้องประชุมออนไลน์
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/schedule/1" className="btn-link-default">
                                                    ห้องประชุมออฟไลน์
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/schedule/2" className="btn-link-default">
                                                    ห้องประชุมออนไลน์และออฟไลน์
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link onClick={handleLogout} className="btn-logout">
                                            {/* <img src={logout} className="me-2" /> */}
                                             ออกจากระบบ
                                        </Link>
                                    </li>
                                </ul>
                                : null}
                        </Menu>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Menuleft