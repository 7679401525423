import React, { useEffect, useState, useRef } from 'react';

import { useParams } from "react-router-dom";

// import logo from '../assets/img//logo-white.svg';
import profile from '../assets/img//profile.svg';
import bg from '../assets/img/bg.jpg';
import p1 from '../assets/img/p1.png';
// import p2 from '../assets/img/p2.jpg';
// import p3 from '../assets/img/p3.jpg';
// import p4 from '../assets/img/p4.jpg';

import Menu from '../components/menu'

import { getUser } from '../services/authenService';


const Profile = () => {

    const pr = useParams();

    const [isLoading, setIsLoading] = useState(false)
    // const [isTitle, setIsTitle] = useState(true)
    const [userData, setUserData] = useState({})

    const fetchUser = async () => {

        setIsLoading(true)
        // if (!auth || !auth.id)
        //     setTimeout(() => { window.location.reload() }, 500);

        const user = await getUser(pr.id)

        // console.log(user)

        if (user.status === 200 || user.status === 'success') {

            setUserData(user.data)
            // listReserve(startDate)
            // window.location.href = '/profile'
            setIsLoading(false)
        } else {
            window.location.href = '/'
        }
    }

    const called = useRef(true)

    useEffect(() => {

        // listBlog()

        if (called.current) {
            // if (!localStorage.getItem('accessToken'))
            // window.location.href = '/'
            fetchUser()
        }



        return () => {
            called.current = false;
        }

        // setTimeout(() => setIsTitle(false), 3000);
    }, [])

    const pad = (n) => {
        var string = "" + n;
        var pad = "000000";
        return pad.substring(0, pad.length - string.length) + string;

    }


    return (
        <>

            <div className="container-fluid col-12 col-md-3 col-lg-3 overflow-hidden">
                <div className="row justify-content-center">

                    <div className='col-12 col-md-12 col-lg-12 position-relative p-0 bg-norepeat' style={{ backgroundImage: `url(${bg})`, minHeight: '100vh' }}>
                        <Menu udata={null} />
                        <div className="">
                            {/* <Link to="/">Home</Link> */}
                            <img src={profile} className="w-50 px-5 mt-5 box-img-shadow" style={{}} />
                        </div>
                        <div className='pt-5 pb-4' style={{ background: "#fff", borderTopLeftRadius: "40px", borderTopRightRadius: "40px", marginTop: "-50px" }}>
                            <div className='py-4 px-3'>
                                <div className='row text-start'>
                                    <div className='col-12 font-weight-600' style={{ color: "#54519D" }}>ID : {pad(userData.id)}</div>
                                    <div className='col-6'>
                                        <div className='mt-2 font-size-12 font-weight-600'>First name</div>
                                        <div className='font-size-14'>{userData.firstName}</div>

                                        <div className='mt-2 font-size-12 font-weight-600'>Mobile Number</div>
                                        <div className='font-size-14'>{userData.mobile}</div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='mt-2 font-size-12 font-weight-600'>Last name</div>
                                        <div className='font-size-14'>{userData.lastName}</div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='mt-2 font-size-14'>Registration is finished. Please wait for the confirmation email.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-start py-4 px-3' style={{ background: "#F5F5F5" }}>
                            <div className='font-size-20 font-weight-700'>COURSES</div>
                            <div className='py-3 px-2'>
                                <div className='row text-start'>
                                    <div className='col-6 p-1'>
                                        <div><img src={p1} className="w-100" /></div>
                                        <div className='mt-2 font-size-14 font-weight-600' style={{ lineHeight: "1" }}>
                                        Effectiveness of Treatment Plan Review for Clear Aligners
                                        </div>
                                        {/* <div className='font-size-10 pb-2'>01/2023 - 12/2023</div> */}
                                    </div>
                                    {/* <div className='col-6 p-1'>
                                        <div><img src={p2} className="w-100" /></div>
                                        <div className='mt-2 font-size-14 font-weight-600' style={{ lineHeight: "1" }}>CHECK TOOTH</div>
                                        <div className='font-size-10 pb-2'>01/2023 - 12/2023</div>
                                    </div>
                                    <div className='col-6 p-1'>
                                        <div><img src={p3} className="w-100" /></div>
                                        <div className='mt-2 font-size-14 font-weight-600' style={{ lineHeight: "1" }}>CHECK TOOTH</div>
                                        <div className='font-size-10 pb-2'>01/2023 - 12/2023</div>
                                    </div>
                                    <div className='col-6 p-1'>
                                        <div><img src={p4} className="w-100" /></div>
                                        <div className='mt-2 font-size-14 font-weight-600' style={{ lineHeight: "1" }}>CHECK TOOTH</div>
                                        <div className='font-size-10 pb-2'>01/2023 - 12/2023</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='font-size-10 color-white py-2' style={{ backgroundColor: "#532C76" }}>Copyright © 2023 Modern Dental Group Limited. All rights reserved.</div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Profile;